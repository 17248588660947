* {
  margin: 0;
  padding: 0;
}
.Gradient {
  background: linear-gradient(
    to right,
    rgba(48, 93, 171, 1),
    rgba(75, 182, 73, 1)
  );
}
body {
  /* background-image: url("./background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  filter: ; */
  background-color: #f8f9fa;
}

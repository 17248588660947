.expand {
  transition-duration: 0.5s;
  overflow: hidden;
}
.collapsed {
  transition-duration: 0.5s;
  overflow: hidden;
}
.rotate-pre {
  transform: rotate(90deg);
  transition-duration: 0.25s;
  cursor: pointer;
}
.rotate-post {
  transform: rotate(0deg);
  transition-duration: 0.25s;
  cursor: pointer;
}
.list-group.pad {
  margin: 0.5rem;
}
strong {
  margin-left: 0.5rem;
}
.list-group-item {
  white-space: pre-wrap;
}

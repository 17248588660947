.header {
  height: 3rem;
  color: white;
  text-align: center;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p:nth-child(1) {
  font-size: 1rem;
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  border-radius: 10px;
  position: absolute;
  top: 0.25rem;
  left: 1rem;
  transition-duration: 0.5s;
}
.p:nth-child(2) {
  font-size: 1rem;
  padding: 0.5rem;
  background-color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  border-radius: 10px;
  position: absolute;
  top: 0.25rem;
  left: 5.25rem;
  transition-duration: 0.5s;
}
.p:hover {
  background-color: rgba(0, 0, 0, 0.5);
  transition-duration: 0.25s;
}
.h1 {
  font-size: 2rem;
  padding: 0;
}
.title {
  font-family: Arial, Helvetica, sans-serif;
  font-style: italic;
  font-size: 1.5rem;
}

.gradient-top {
  text-align: center;
  font-size: 3em;
  font-weight: 400;
  color: #fff;
  margin-top: 0px;
  margin-bottom: 40px;
  padding-top: 6px;
  padding-bottom: 16px;
  background: #305daa; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(
    left,
    rgba(48, 93, 171, 1),
    rgba(48, 93, 171, 1),
    rgba(62, 138, 121, 1),
    rgba(75, 182, 73, 1),
    rgba(75, 182, 73, 1)
  ); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    right,
    rgba(48, 93, 171, 1),
    rgba(48, 93, 171, 1),
    rgba(62, 138, 121, 1),
    rgba(75, 182, 73, 1),
    rgba(75, 182, 73, 1)
  ); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    right,
    rgba(48, 93, 171, 1),
    rgba(48, 93, 171, 1),
    rgba(62, 138, 121, 1),
    rgba(75, 182, 73, 1),
    rgba(75, 182, 73, 1)
  ); /* For Firefox 3.6 to 15 */
  background: linear-gradient(
    to right,
    rgba(48, 93, 171, 1),
    rgba(48, 93, 171, 1),
    rgba(62, 138, 121, 1),
    rgba(75, 182, 73, 1),
    rgba(75, 182, 73, 1)
  ); /* Standard syntax */ /*header for page titles large size*/
}
h2 {
  margin-bottom: 26px;
  color: #2d2d2d;
  margin-top: 20px;
  font-size: 1.4em; /*header for container*/
}
.row-grey {
  background-color: #d6d6d6;
  padding-bottom: 20px;
  margin-top: 40px;
}
.btn-grey {
  margin-top: 1rem;
  margin-bottom: 5rem;
}
.col-padding {
  padding-top: 0px;
  padding-bottom: 20px;
}
.col-margin {
  margin-top: 40px;
  margin-bottom: 40px;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

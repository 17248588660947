.alert-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  /* min-height: 100vh; */
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 100;
}
.alert-body {
  margin-left: 25%;
  width: 50%;
  margin-top: 10%;
  border-radius: 10px;
  transition-duration: 0.5s;
  border: solid 4px transparent;
}
.alert-header {
  padding: 0.5rem;
  text-align: center;
}
.alert-header > h1 {
  font-weight: 300;
  margin: 0;
}
.alert-p {
  padding: 0.5rem;
  text-align: center;
  margin: 0;
}
.alert-btns {
  padding: 0.5rem;
  margin: 0;
}
.alert-btns > button {
  width: 10%;
  margin-left: 45%;
  border-radius: 5px;
  outline: none;
  border: solid 2px transparent;
  background-color: rgba(255, 255, 255, 0);
  transition-duration: 0.5s;
}
.alert-btns > button:hover {
  background-color: rgba(255, 255, 255, 0.25);
  transition-duration: 0.5s;
}
.hide {
  opacity: 0;
  transition-duration: 0.25s;
}
.show {
  opacity: 1;
  transition-duration: 0.25s;
}
.delete {
  display: none;
}

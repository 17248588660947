.p-ctl {
  position: absolute;
  right: 0.25rem;
  top: 0.025rem;
  padding: 0.25rem;
  padding-top: 0.5rem;
  width: 4rem;
}
.p-ctl > .x {
  background-color: #dc3545;
  color: white;
  padding: 0.25rem;
  width: 50%;
}
.p-ctl > .x- {
  background-color: #28a745;
  color: white;
  padding: 0.25rem;
  width: 50%;
}
.bg-success.padding-10px {
  cursor: pointer;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  transition-duration: 0.25s;
}
.bg-success {
  transition-duration: 0.25s;
}
.bg-info {
  border-radius: 0px !important;
  transition-duration: 0.25s;
}
.bg-danger {
  border-radius: 0px !important;
  background-color: #fd7e14 !important;
  transition-duration: 0.25s;
}
.bg-warning {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  transition-duration: 0.25s;
}
.padding-10px {
  padding: 10px !important;
  border-radius: 5px;
  transition-duration: 0.25s;
}
.bg-success:hover {
  background-color: #19692c !important;
  transition-duration: 0.25s;
  cursor: pointer;
}
.bg-warning:hover {
  background-color: #ba8b00 !important;
  transition-duration: 0.25s;
  cursor: pointer;
}
.bg-danger:hover {
  background-color: #c35a02 !important;
  transition-duration: 0.25s;
  cursor: pointer;
}
.bg-info:hover {
  background-color: #0f6674 !important;
  transition-duration: 0.25s;
  cursor: pointer;
}
.list-group-item:nth-child(even) {
  background-color: #f2f2f2;
}
.display-4.sm {
  font-size: 1.5rem;
  margin-left: 0.5rem;
  margin-top: 0.5rem;
}
.rotate-main-pre {
  transform: rotate(0deg);
  transition-duration: 0.25s;
  cursor: pointer;
}
.rotate-main-post {
  transform: rotate(180deg);
  transition-duration: 0.25s;
  cursor: pointer;
}
.input-group.center {
  margin-top: 0.2rem;
  width: 100%;
}
.no-gap {
  margin: 0 !important;
  padding: 0 !important;
  height: 100%;
  width: 100%;
}
.no-gap.list-group-item {
  height: 3rem;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.full {
  height: 100%;
  color: white;
  border: none;
  border-radius: inherit;
}
.full:nth-child(1) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.full:nth-child(2) {
  border-bottom-left-radius: 0;
}
.qtr {
  width: 80%;
}
.qtr3 {
  width: 20%;
}
